<template>
  <v-form ref="form" class="mx-2" method="post" enctype="multipart/form-data" onkeydown="return event.key != 'Enter';">
  <v-container id="GESTIONEPRENOTAZIONI" fluid tag="section">
      <v-row>
        <v-col :cols="isEditPrenotazione ? '4' : '12'">
          <base-material-card
            color="white"
            iconcolor="darkred"
            icon="mdi-calendar-check"
            :title="$t('prenotazioni.titolo')"
            class="px-5 py-3"
          >
            <v-container v-if="isCreatedCorrectly" v-resize="onContainerChange">
              <v-row>
                <v-col>
                  <v-card-title>
                    <v-text-field
                      v-model="search"
                      append-icon="mdi-magnify"
                      :label="$t('prenotazioni.ricerca')"
                      single-line
                      hide-details
                    ></v-text-field>
                  </v-card-title>
                </v-col>
                <v-col v-if="$store.state.roleSel.CCode == 'ADM'" >
                  <v-checkbox
                    v-model="visualizzaStorico"
                    :label="$t('visualizzaStorico')"
                    hide-details
                    @change="checkStorico($event)"
                  >
                  </v-checkbox>
                </v-col>

                <v-col align="end">
                  <v-btn color="darkred" width="180px" @click="onNuovaPrenotazione">
                    <v-icon left> mdi-plus-circle-outline </v-icon>
                    {{ $t("prenotazioni.nuovaPrenotazione") }}
                  </v-btn>
                </v-col>
                <v-dialog v-model="dialogNuovaPrenot" max-width="800px" >
                <v-card>
                  <v-card-title class="text-h5"
                    > {{ $t("prenotazioni.nuovaPrenotazioneVeicolo") }} </v-card-title
                  >
                  <v-row style="margin-left:10px; margin-top:20px;">
                    <v-col cols="11" style="">
                      <v-text-field
                        v-model="prenotazioneNew.dataPrenotText"
                        :label="$t('prenotazioni.dataPrenotazioneObbl')"
                        :rules="[rules.required]"
                        type="date"
                        min="1990-01-01"
                        max="2050-12-31"
                        clearable
                        >
                      </v-text-field>
                    </v-col>
                  </v-row>
                  <v-row style="margin-left:10px;margin-right=20px;">
                    <v-col cols="5">
                      <v-select
                        :items="oreDisp"
                        v-model="prenotazioneNew.oraInizioText"
                        :rules="[rules.required]"
                        :label="$t('prenotazioni.oraInizioObbl')"
                        >
                      </v-select>
                    </v-col>
                    <v-col cols="1">
                    </v-col>
                    <v-col cols="5" style="margin-right=20px;">
                      <v-select
                        :items="oreDispDa"
                        v-model="prenotazioneNew.oraFineText"
                        :rules="[rules.required]"
                        :label="$t('prenotazioni.oraFineObbl')"
                        >
                      </v-select>
                    </v-col>
                  </v-row>
                  <v-row style="margin-left:10px;margin-right=20px;">
                    <v-col cols="5">
                      <v-select
                        :items="t_tipo_sharing"
                        v-model="prenotazioneNew.tipoVeicoloSharing"
                        :rules="[rules.selectRequired]"
                        :label="$t('prenotazioni.tipoSharingObbl')"
                        return-object
                        item-text="cDescrizione"
                        item-value="id"
                        >
                      </v-select>
                    </v-col>
                    <v-col cols="1">
                    </v-col>
                    <v-col cols="5">
                      <v-select
                        :items="t_tipo_trasporto"
                        v-model="prenotazioneNew.tipoVeicoloTipTras"
                        :label="$t('prenotazioni.tipoTrasporto')"
                        return-object
                        item-text="cDescrizione"
                        item-value="id"
                      >
                      </v-select>
                    </v-col>
                  </v-row>
                  <v-row style="margin-left:10px;">
                    <v-col cols="11">
                      <v-text-field
                        v-model="prenotazioneNew.nPosti"
                        type="number"
                        min="0"
                        max="99"
                        :rules="[rules.valueMin(prenotazioneNew.nPosti, -1), rules.valueMax(prenotazioneNew.nPosti, 100)]"
                        :label="$t('prenotazioni.nPosti')"
                        @keydown="onKeyDown"
                        >
                      </v-text-field>
                    </v-col>
                  </v-row>
                    <v-spacer></v-spacer>
                    <v-col align="center">
                      <v-btn
                        tile
                        color="darkred"
                        @click="onRicercaVeicoliDisp"
                        >Ricerca
                      </v-btn>
                    </v-col>
                    <v-spacer></v-spacer>
                    <v-col>
                      <v-data-table
                        id="tblVeicoliDisp"
                        :items="veicoliDisp"
                        :headers="headersColumnsVeicoliDisp"
                        :value="[veicoloSel]"
                        item-key="eVeicoloId"
                        :items-per-page="-1"
                        single-select
                        class="elevation-1"
                        @click:row="onVeicoloSelClick"
                      >
                      </v-data-table>            
                    </v-col>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                      color="blue darken-1"
                      text
                      @click="onClosePrenotazione"
                      >Annulla</v-btn
                    >
                    <v-btn
                      color="blue darken-1"
                      text
                      @click="onConfirmPrenotazione"
                      >Prenota</v-btn
                    >
                    
                    <v-spacer></v-spacer>
                  </v-card-actions>
                  <v-col cols="11">
                    <v-alert
                      id="alertPrenotazione"
                      shaped
                      :value="isAlertPrenotazione"
                      :type="alertType"
                      dismissible
                    >
                      {{ messaggioAlert }}
                    </v-alert>
                  </v-col>
                </v-card>
              </v-dialog>
              </v-row>
            </v-container>
            <v-data-table
              style="max-height:600px; overflow:auto"
              id="tblMainPrenotaz"
              :items="prenotazioni.source"
              :headers="headersColumns"
              :search="search"
              :value="[prenotazioni.selected]"
              :items-per-page="-1"
              @click:row="onTableRowClick"
              single-select
              :loading="isloading_mainGrid"
              class="elevation-1"
              loading-text="Caricamento dati in corso..."
            >
          </v-data-table>            
        </base-material-card>
      </v-col>

      <v-col v-if="isEditPrenotazione" cols="8">
          <base-material-card
            color="white"
            iconcolor="darkred"
            icon="mdi-calendar-check-outline"
            :title="$t('prenotazioni.prenotazioniVeicolo') + ': ' + prenotazioni.selected.marcaModelloVeicolo"
            class="px-5 py-3"
          >
              <v-card  flat>
                <v-card-title
                  class="black--text"
                  style="margin-bottom: 20px; margin-top: 20px"
                  >{{ $t("prenotazioni.dettaglio") }}
                </v-card-title>

                  <v-row style="margin-left 20px;">
                    <v-col>
                      <v-text-field
                        v-model="prenotazioni.selected.marcaModelloVeicolo"
                        :label="$t('prenotazioni.modello')"
                        disabled
                      >
                      </v-text-field>
                    </v-col>
                    <v-col>
                      <v-text-field
                        v-model="prenotazioni.selected.cTarga"
                        :label="$t('prenotazioni.targa')"
                        disabled
                      >
                      </v-text-field>
                    </v-col>
                    <v-col>
                      <v-text-field
                        v-model="prenotazioni.selected.nPosti"
                        :label="$t('prenotazioni.nPosti')"
                        disabled
                      >
                      </v-text-field>
                    </v-col>
                  </v-row>
                  <v-row style="margin-left 20px;">
                    <v-col>
                      <v-text-field
                        v-model="prenotazioni.selected.ubicazioneVeicolo"
                        :label="$t('prenotazioni.ubicazioneVeicolo')"
                        disabled
                      >
                      </v-text-field>
                    </v-col>
                    <v-col>
                      <v-textarea
                        v-model="prenotazioni.selected.notaDotazione"
                        outlined
                        :label="$t('prenotazioni.notaDotazione')"
                        readonly
                      >
                      </v-textarea>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <v-select
                        :items="t_tipo_sharing"
                        v-model="prenotazioni.selected.tipoVeicoloSharing"
                        label="Tipo Sharing"
                        return-object
                        item-text="cDescrizione"
                        item-value="id"
                        disabled
                        >
                      </v-select>
                    </v-col>
                    <v-col>
                      <v-select
                        :items="t_tipo_trasporto"
                        v-model="prenotazioni.selected.tipoVeicoloTipTras"
                        :label="$t('prenotazioni.tipoTrasporto')"
                        return-object
                        item-text="cDescrizione"
                        item-value="id"
                        disabled
                        >
                      </v-select>
                    </v-col>
                    <v-col>
                      <v-text-field
                        v-model="prenotazioni.selected.servizioDTO.cDescrizione"
                        :label="$t('prenotazioni.servizio')"
                        disabled
                      >
                      </v-text-field>
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col>
                      <v-text-field
                        v-model="prenotazioni.selected.dataPrenotText"
                        :label="$t('prenotazioni.dataPrenotazione')"
                        type="date"
                        min="1990-01-01"
                        max="2050-12-31"
                        disabled
                      >
                      </v-text-field>
                    </v-col>

                    <v-col>
                      <v-select
                        :items="oreDisp"
                        v-model="prenotazioni.selected.oraInizioText"
                        label="Ora inizio"
                        :disabled="shouldDisableAdm">>
                      </v-select>
                    </v-col>
                    <v-col>
                      <v-select
                        :items="oreDispDa"
                        v-model="prenotazioni.selected.oraFineText"
                        :rules="[rules.required]"
                        label="Ora fine"
                        :disabled="shouldDisable">
                      </v-select>
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col>
                      <v-text-field
                        v-model="prenotazioni.selected.utentePrenotDTO.cNomeCognome"
                        :label="$t('prenotazioni.utentePrenotatore')"
                        disabled
                      >
                      </v-text-field>
                    </v-col>
                    <v-col>
                      <v-text-field
                        v-model="prenotazioni.selected.dataInsText"
                        :label="$t('prenotazioni.prenotatoIl')"
                        type="date"
                        min="1990-01-01"
                        max="2050-12-31"
                        disabled
                      >
                      </v-text-field>
                    </v-col>
                    <v-col>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <v-textarea
                        v-model="prenotazioni.selected.xNote"
                        outlined
                        name="txtNote"
                        :label="$t('prenotazioni.note')"
                        :disabled="shouldDisable"
                      >
                      </v-textarea>
                    </v-col>
                  </v-row>

                </v-card>
            
            <v-divider class="mx-4"></v-divider>
            <v-row
              style="margin-top: 20px; margin-left: 20px; margin-bottom: 10px"
            >
              <v-alert
                id="alertDettaglio"
                shaped
                :value="isAlertDetailVisible"
                :type="alertType"
                dismissible
              >
                {{ messaggioAlert }}
              </v-alert>
            </v-row>

            <v-row style="margin-top: 20px; margin-left: 20px; margin-bottom: 10px">
              <v-btn
                tile
                color="darkred"
                v-if="!isHidden"
                v-on:click="isHidden = true"
                @click="onEditClick"
              >
                <v-icon left> mdi-pencil </v-icon>
                {{ $t("modifica") }}
              </v-btn>
              <v-btn
                tile
                color="darkred"
                v-if="isHidden"
                @click="onSaveClick"
              >
                <v-icon left> mdi-content-save </v-icon>
                {{ $t("salva") }}
              </v-btn>
              <v-btn
                tile
                color="darkred"
                v-if="!isHidden"
                @click="onTerminaClick"
              >
                <v-icon left> mdi-pencil </v-icon>
                {{ $t("prenotazioni.termina") }}
              </v-btn>
              <v-btn
                tile
                color="darkred"
                v-if="!isHidden"
                @click="onAnnullaClick"
              >
                <v-icon left> mdi-pencil </v-icon>
                {{ $t("prenotazioni.annullaPrenotazione") }}
              </v-btn>
              <v-btn id="btnChiudi" tile color="darkred" @click="onCloseDetail">
                <v-icon left> mdi-door-open </v-icon>
                {{ $t("chiudi") }}
              </v-btn>
              <!-- TERMINA PRENOTAZIONE -->
              <v-dialog v-model="dialogTerminaPrenot" max-width="500px">
                <v-card>
                  <v-card-title class="text-h5"
                    >Sei sicuro di voler terminare la prenotazione selezionata?
                  </v-card-title>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                      color="blue darken-1"
                      text
                      @click="closeTerminaPrenot"
                      >Annulla</v-btn
                    >
                    <v-btn
                      color="blue darken-1"
                      text
                      @click="confirmTerminaPrenot"
                      >OK</v-btn
                    >
                    <v-spacer></v-spacer>
                  </v-card-actions>
                </v-card>
              </v-dialog>    
              <!-- ANNULLA PRENOTAZIONE -->
              <v-dialog v-model="dialogAnnullaPrenot" max-width="500px">
                <v-card>
                  <v-card-title class="text-h5"
                    >Sei sicuro di voler annullare la prenotazione selezionata?
                  </v-card-title>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                      color="blue darken-1"
                      text
                      @click="closeAnnullaPrenot"
                      >Annulla</v-btn
                    >
                    <v-btn
                      color="blue darken-1"
                      text
                      @click="confirmAnnullaPrenot"
                      >OK</v-btn
                    >
                    <v-spacer></v-spacer>
                  </v-card-actions>
                </v-card>
              </v-dialog>              
            </v-row>

          </base-material-card>
          
      </v-col>
    </v-row>
      <v-row>
        <v-alert
          id="alertGrid"
          shaped
          :value="isAlertGridVisible"
          :type="alertType"
          dismissible
        >
          {{ messaggioAlert }}
        </v-alert>
      </v-row>

  </v-container>
  </v-form>
</template>

<script>
import Vue from "vue";
import Component from "vue-class-component";

import i18n from "@/i18n";

import Utils from "@/utils/Utils";
import UtilsDate from "@/utils/UtilsDate";

import PrenotazioniService from "@/service/PrenotazioniService";
import TipologicheService from "@/service/TipologicheService";
import VeicoliService from "@/service/VeicoliService";


@Component
export default class GestionePrenotazioni extends Vue {
  prenotazioni = {
    selected: null,
    source: [],
  };

  headersColumnsOrig = [
    { text: "Modello", value: "marcaModelloVeicolo" },
    { text: "Targa", value: "cTarga" },
    { text: "Ente", value: "servizioDTO.enteDTO.cDescrizione" },
    { text: "Data Prenotazione", value: "dataPrenotText" },
    { text: "Ora Inizio", value: "oraInizioText" },
    { text: "Ora Fine", value: "oraFineText" },
    { text: "Tipo Sharing", value: "tipoVeicoloSharing.cDescrizione" },
    { text: "Tipo Trasporto", value: "tipoVeicoloTipTras.cDescrizione" },
    { text: "N° Posti pren.", value: "nPosti" },
  ];

  headersColumnsRidotte = [
    { text: "Modello", value: "marcaModelloVeicolo" },
    { text: "Targa", value: "cTarga" },
    { text: "Data Prenotazione", value: "dataPrenotText" },
    { text: "Ora Inizio", value: "oraInizioText" },
    { text: "Ora Fine", value: "oraFineText" },
  ];

  headersColumnsVeicoliDisp = [
    { text: "Modello", value: "marcaModelloVeicolo" },
    { text: "Targa", value: "cTarga" },
    { text: "Ubicazione", value: "ubicazioneVeicolo" },
    { text: "Data Inizio", value: "oraInizioText" },
    { text: "Data Fine", value: "oraFineText" },
    { text: "Posti", value: "nPosti" },
    { text: "Note", value: "notaDotazione" },
  ];

  oreDisp = [ "00:00", "00:15" , "00:30" , "00:45" , 
                "01:00", "01:15" , "01:30" , "01:45" ,
                "02:00", "02:15" , "02:30" , "02:45" ,
                "03:00", "03:15" , "03:30" , "03:45" ,
                "04:00", "04:15" , "04:30" , "04:45" ,
                "05:00", "05:15" , "05:30" , "05:45" ,
                "06:00", "06:15" , "06:30" , "06:45" ,
                "07:00", "07:15" , "07:30" , "07:45" ,
                "08:00", "08:15" , "08:30" , "08:45" ,
                "09:00", "09:15" , "09:30" , "09:45" ,
                "10:00", "10:15" , "10:30" , "10:45" ,
                "11:00", "11:15" , "11:30" , "11:45" ,
                "12:00", "12:15" , "12:30" , "12:45" ,
                "13:00", "13:15" , "13:30" , "13:45" ,
                "14:00", "14:15" , "14:30" , "14:45" ,
                "15:00", "15:15" , "15:30" , "15:45" ,
                "16:00", "16:15" , "16:30" , "16:45" ,
                "17:00", "17:15" , "17:30" , "17:45" ,
                "18:00", "18:15" , "18:30" , "18:45" ,
                "19:00", "19:15" , "19:30" , "19:45" ,
                "20:00", "20:15" , "20:30" , "20:45" ,
                "21:00", "21:15" , "21:30" , "21:45" ,
                "22:00", "22:15" , "22:30" , "22:45" ,
                "23:00", "23:15" , "23:30" , "23:45" ,
              ];

oreDispDa = [ "00:00", "00:15" , "00:30" , "00:45" , 
                "01:00", "01:15" , "01:30" , "01:45" ,
                "02:00", "02:15" , "02:30" , "02:45" ,
                "03:00", "03:15" , "03:30" , "03:45" ,
                "04:00", "04:15" , "04:30" , "04:45" ,
                "05:00", "05:15" , "05:30" , "05:45" ,
                "06:00", "06:15" , "06:30" , "06:45" ,
                "07:00", "07:15" , "07:30" , "07:45" ,
                "08:00", "08:15" , "08:30" , "08:45" ,
                "09:00", "09:15" , "09:30" , "09:45" ,
                "10:00", "10:15" , "10:30" , "10:45" ,
                "11:00", "11:15" , "11:30" , "11:45" ,
                "12:00", "12:15" , "12:30" , "12:45" ,
                "13:00", "13:15" , "13:30" , "13:45" ,
                "14:00", "14:15" , "14:30" , "14:45" ,
                "15:00", "15:15" , "15:30" , "15:45" ,
                "16:00", "16:15" , "16:30" , "16:45" ,
                "17:00", "17:15" , "17:30" , "17:45" ,
                "18:00", "18:15" , "18:30" , "18:45" ,
                "19:00", "19:15" , "19:30" , "19:45" ,
                "20:00", "20:15" , "20:30" , "20:45" ,
                "21:00", "21:15" , "21:30" , "21:45" ,
                "22:00", "22:15" , "22:30" , "22:45" ,
                "23:00", "23:15" , "23:30" , "23:45" ,
              ];

  t_tipo_sharing = [];
  t_tipo_sharingOrig = [];

  t_tipo_trasporto = [];
  t_tipo_trasportoOrig = [];

  rules = Utils.rules;

  // Variabili
  isEditPrenotazione = false;
  isCreatedCorrectly = false;
  shouldDisable = true;
  shouldDisableAdm = true;
  isHidden = false;
  visualizzaStorico = false;
  isloading_mainGrid = false;
  dialogNuovaPrenot = false;
  dialogAnnullaPrenot = false;
  dialogTerminaPrenot = false;
  prenotazioneTempl = null;
  prenotazioneNew = [];
  veicoliDisp = [];
  veicoloSel = [];

  isAlertPrenotazione = false;
  isAlertGridVisible = false;
  isAlertDetailVisible = false;
  messaggioAlert = "";
  alertType = "error";

  dataPrenSel = null;
  numPostiSel = ""
  oreDaSel = ""
  oreASel = ""
  sharingSel = ""
  trasportoSel = ""

  veicoliPrenot = [];
  time = '09:00'

  dimension = 0;
  
  search = "";
  

  get headersColumns() {
    let tblWidth = this.dimension;
    //console.log(tblWidth);
    if (tblWidth < 1000) return this.headersColumnsRidotte;
    else 
        return this.headersColumnsOrig;
  }

  mounted() {
    if (this.isCreatedCorrectly)
      this.onContainerChange();

    //this.$refs.calendar.checkChange()
  }

  created() {
    let verLoadingPage = Utils.verifyLoadingPage(this.$store, "W_PRENOTAZIONI");
    switch (verLoadingPage)
    {
      case 0: // All OK
        this.isCreatedCorrectly = true;
        this.loadPrenotazioniTemplate();
        this.loadAllTipologiche();

        var veicoloId = -1;
        this.loadPrenotazioni(veicoloId);
        
        break;
      
      case 1: // Utente non loggato
        AuthService.logout();
        break;

      case 2: // Ente non selezionato
        this.$router.push({ name: "Dashboard" });
        break;

      case 3: // Permesso alla pagina non presente
        this.$router.push({ name: "Dashboard" });
    }
  }

  onContainerChange() {
    let self = this;
    // console.log("onContainerChange");
    self.$nextTick(function () {
      self.dimension = document.getElementById("tblMainPrenotaz").offsetWidth;
      return self.dimension;
    });
  }

  onKeyDown(key) {
    if (key.key == "." || key.key == "," || key.key == "-")
    {
      key.preventDefault();
    }
    else
      return true;
  }

  onTableRowClick(prenot, row) {
    this.prenotazioni.selected = prenot;
    console.log("Prenotazione selezionata: ");
    console.log(this.prenotazioni.selected);

    //this.prenotazioneSel= this.prenotazioneTempl; // Necessario altrimenti va in errore
    this.isEditPrenotazione = true;
    this.isHidden = false;
    this.onContainerChange();
    this.shouldDisable = true;
    this.shouldDisableAdm = true;
  }

  onVeicoloSelClick(veicoloPrenot, row) {
    this.veicoloSel = veicoloPrenot;
    console.log("Veicolo selezionata: ");
    console.log(this.veicoloSel);
  }

  onPrenotazioniClick(prenot, row) {
    this.prenotazioni.selected = prenot;
    console.log("Prenotazione selezionata: ");
    console.log(this.prenotazioni.selected);

    this.onContainerChange();
    this.shouldDisable = true;
    this.shouldDisableAdm = true;
    this.isHidden = false;
  }

  onNuovaPrenotazione() {
    var enteId = this.$store.state.enteSel.id;
    this.prenotazioneNew = Object.assign({}, this.prenotazioneTempl);
    this.veicoliDisp = [];

    // Tipologiche attive
    this.loadAllTipologicheAttive(true);

    this.$refs.form.resetValidation();
    if (enteId > 0)
      this.dialogNuovaPrenot = true;
    else {
        this.alertType = "error";
        this.messaggioAlert = i18n.t("prenotazioni.erroreSelezioneEnte");
        this.showHide_alertGrid(8000);
    }
  }

  onCloseDetail() {
    this.isEditPrenotazione = false;
    this.onContainerChange();
    this.isHidden = false;
    this.shouldDisable = true;
    this.shouldDisableAdm = true;
  }

  onEditClick() {
    // Tipologiche attive
    this.loadAllTipologicheAttive(true);

    this.shouldDisable = false;
    if (this.$store.state.roleSel.CCode == "ADM") 
      this.shouldDisableAdm = false;
  }

  onSaveClick() {

      // Verifico che l'ora inizio sia superiore dall'ora fine
    if (this.prenotazioni.selected.oraInizioText >= this.prenotazioni.selected.oraFineText)
    {
      this.alertType = "error";
      this.messaggioAlert = i18n.t("erroreDataInizioFine");
      this.showHide_alertDetail(8000);
      return;
    }

    this.savePrenotazioneDto();
  }

  onRicercaVeicoliDisp() {
    this.veicoloSel = [];
    //this.prenotazioneNew = Object.assign({}, this.prenotazioneTempl);
    var formIsValid = this.$refs.form.validate();
    if (!formIsValid) {
      this.alertType = "error";
      this.messaggioAlert = i18n.t("prenotazioni.erroreCampiObbligatori");
      this.showHide_alertPrenotazione(8000);
      return;
    } else {
      // Verifico che la data sia più recente di oggi
      const today = new Date();
      const yyyy = today.getFullYear();
      let mm = today.getMonth() + 1; // Months start at 0!
      let dd = today.getDate();
      if (dd < 10) dd = '0' + dd;
      if (mm < 10) mm = '0' + mm;

      var newToday = yyyy + '-' + mm + '-' + dd;
      var oggi = Date.parse(newToday);
      var dataPrenSel = Date.parse(this.prenotazioneNew.dataPrenotText);
      //var dateNow = Date.now();
      if (dataPrenSel < oggi)
      {
        this.alertType = "error";
        this.messaggioAlert = i18n.t("prenotazioni.erroreDataPrenotazione");
        this.showHide_alertPrenotazione(8000);
        return;
      }

      if (Utils.isNullOrUndefinedOrEmpty(this.prenotazioneNew.nPosti))
      {
        this.prenotazioneNew.nPosti = 0;
      }
      // Verifico che l'ora inizio sia superiore dall'ora fine
      if (this.prenotazioneNew.oraInizioText >= this.prenotazioneNew.oraFineText)
      {
        this.alertType = "error";
        this.messaggioAlert = i18n.t("erroreDataInizioFine");
        this.showHide_alertPrenotazione(8000);
        return;
      }
      this.loadPrenotazioniDisponibili();
    }
    
  }

  onConfirmPrenotazione() {
    var formIsValid = this.$refs.form.validate();
    if (!formIsValid) {
      this.alertType = "error";
      this.messaggioAlert = i18n.t("prenotazioni.erroreCampiObbligatori");
      this.showHide_alertPrenotazione(8000);
      return;
    } else {
      // Verifico che sia selezionato il veicolo
      if (this.veicoloSel == null || this.veicoloSel.length == 0 )
      {
        this.alertType = "error";
        this.messaggioAlert = i18n.t("prenotazioni.erroreVeicoloDaSelezionare");
        this.showHide_alertPrenotazione(8000);
        return;
      }
      else
      // Prenoto
      this.createPrenotazioneDto();
    }
  }

  onClosePrenotazione() {
    this.dialogNuovaPrenot = false;
  }

  onTerminaClick() {
    this.dialogTerminaPrenot = true;
  }

  confirmTerminaPrenot() {
    this.terminaPrenotazioneDto();
    this.dialogTerminaPrenot = false;
  }

  closeTerminaPrenot() {
    this.dialogTerminaPrenot = false;
  }

  onAnnullaClick() {
    this.dialogAnnullaPrenot = true;
  }

  confirmAnnullaPrenot() {
    this.deletePrenotazioneDto(this.prenotazioni.selected.id);
    this.dialogAnnullaPrenot = false;
  }

  closeAnnullaPrenot() {
    this.dialogAnnullaPrenot = false;
  }

  checkStorico(e) {
    var enteId = this.$store.state.enteSel.id;

    // Caricamento oggetto principale
    var veicoloId = -1;
    this.loadPrenotazioni(veicoloId);    
    this.closeDetail();
  }

  showHide_alertGrid(millisecondInterval, event) {
    //console.log('Hide alert');
    this.isAlertGridVisible = true;
    // `event` is the native DOM event
    window.setTimeout(() => {
      this.isAlertGridVisible = false;
      console.log(
        "hide alert after " + millisecondInterval / 1000 + " seconds"
      );
    }, millisecondInterval);
  }

  showHide_alertDetail(millisecondInterval, event) {
    //console.log('Hide alert');
    this.isAlertDetailVisible = true;
    // `event` is the native DOM event
    window.setTimeout(() => {
      this.isAlertDetailVisible = false;
      //console.log("hide alert after " + millisecondInterval/1000 + " seconds");
    }, millisecondInterval);
  }

  showHide_alertPrenotazione(millisecondInterval, event) {
    //console.log('Hide alert');
    this.isAlertPrenotazione = true;
    // `event` is the native DOM event
    window.setTimeout(() => {
      this.isAlertPrenotazione = false;
      console.log(
        "hide alert after " + millisecondInterval / 1000 + " seconds"
      );
    }, millisecondInterval);
  }

  loadAllTipologiche()
  {
      this.loadTVeicoloTipTras();
      this.loadTVeicoloTipSharing();
  }

  loadAllTipologicheAttive(soloAttive) {
    // Caricamento di tutte le Tipologiche
    if (soloAttive)
    {
      // SOLO le tipologiche attive

      this.t_tipo_trasporto = Utils.getTipologicheAttive(this.t_tipo_trasportoOrig);
      this.t_tipo_sharing = Utils.getTipologicheAttive(this.t_tipo_sharingOrig);
    }
    else
    {
      // TUTTE le tipologiche

      this.t_tipo_trasporto = this.t_tipo_trasportoOrig;
      this.t_tipo_sharing = this.t_tipo_sharingOrig;
    }
  }

  /// **************************
  /// DATABASE
  /// **************************

  loadTVeicoloTipTras() {
    var self = this;

    TipologicheService.getTVeicoloTipTras(
      (resp) => {
        console.log("GetTVeicoloTipTras");
        console.log(resp);

        self.t_tipo_trasporto = resp;
        for (let i = 0; i < self.t_tipo_trasporto.length; i++) {
          var item = self.t_tipo_trasporto[i];
          if (item.id < 1)
          {
            self.t_tipo_trasporto.splice(i, 1);
            break;
          }
        }
        self.t_tipo_trasportoOrig = Utils.cloneArray(self.t_tipo_trasporto);
      },
      (err) => {
        console.log("Errore GetTVeicoloTipTras");
        console.log(err);
      }
    );
  }

  loadTVeicoloTipSharing() {
    var self = this;

    TipologicheService.getTVeicoloTipSharing(
      (resp) => {
        console.log("GetTVeicoloTipSharing");
        console.log(resp);

        self.t_tipo_sharing = resp;
        for (let i = 0; i < self.t_tipo_sharing.length; i++) {
          var item = self.t_tipo_sharing[i];
          if (item.id < 1)
          {
            self.t_tipo_sharing.splice(i, 1);
            break;
          }
        }
        self.t_tipo_sharingOrig = Utils.cloneArray(self.t_tipo_sharing);
      },
      (err) => {
        console.log("Errore GetTVeicoloTipSharing");
        console.log(err);
      }
    );
  }

  loadPrenotazioni(veicoloId) {
    var self = this;
    self.isloading_mainGrid = true;

    var enteId = this.$store.state.enteSel.id;
    var userId = this.$store.state.user.id;
    if (this.$store.state.roleSel.CCode == "ADM") 
      userId = -1;
    var storico = this.visualizzaStorico;

    PrenotazioniService.getPrenotazioni(
      enteId,
      userId,
      veicoloId,
      storico,
      (resp) => {
        self.isloading_mainGrid = false;
        console.log("getPrenotazioni");
        console.log(resp);

        self.prenotazioni.source = resp;
        // Aggiorno gli eventi del veicolo
        //self.loadEventsVeicolo();

        const events = []
        for (let i = 0; i < self.prenotazioni.source.length; i++) {
          var item = self.prenotazioni.source[i];
          events.push({
            name: item.cTarga,
            start: new Date(item.hOraInizio),
            end: new Date(item.hOraFine),
            color: 'blue',
            timed: true,
          })
        }
        self.events = events

      },
      (err) => {
        self.isloading_mainGrid = false;
        console.log("Errore getPrenotazioni");
        console.log(err);
        self.alertType = "error";
        self.messaggioAlert = i18n.t("prenotazioni..erroreCaricamentoPrenotazioni");
        self.showHide_alertGrid(8000);
      }
    );
  }

loadPrenotazioniDisponibili() {
    var self = this;
    var enteId = this.$store.state.enteSel.id;
    var userId = this.$store.state.user.id;

    PrenotazioniService.getPrenotazioniDisponibili(
      enteId,
      userId,
      this.prenotazioneNew,
      (resp) => {
        console.log("getPrenotazioniDisponibili");
        console.log(resp);

        self.veicoliDisp = resp;
      },
      (err) => {
        console.log("Errore getPrenotazioni");
        console.log(err);
        self.alertType = "error";
        self.messaggioAlert = Utils.getErrorMessage(err);
        self.showHide_alertPrenotazione(8000);
      }
    );
  }

  loadPrenotazioniTemplate() {
    var self = this;

    PrenotazioniService.getTemplatePrenotazione(
      (resp) => {
        console.log("getTemplatePrenotazione");
        console.log(resp);

        self.prenotazioneTempl = resp;
        self.prenotazioneTempl.nPosti = null;
        self.prenotazioneNew = Object.assign({}, self.prenotazioneTempl);
      },
      (err) => {
        console.log("Errore getTemplatePrenotazione");
        console.log(err);
        self.alertType = "error";
        self.messaggioAlert = Utils.getErrorMessage(err);
        self.showHide_alertGrid(8000);
      }
    );
  }

  createPrenotazioneDto() {
    var self = this;

    PrenotazioniService.createPrenotazione(
      self.veicoloSel,
      (resp) => {
        console.log("createPrenotazione");
        console.log(resp);
        if (resp.result == 1) {
          // Buon fine

          // Ricarico la griglia
          self.alertType = "success";
          self.messaggioAlert = resp.userMsg.message;
          self.showHide_alertGrid(5000);
          self.dialogNuovaPrenot = false;
          // Aggiorno la griglia dopo la creazione
          self.loadPrenotazioni(-1);
        } else {
          // KO
          self.alertType = "error";
          self.messaggioAlert = resp.userMsg.message;
          self.showHide_alertPrenotazione(8000);
        }

        console.log(resp.userMsg.message);
      },
      (err) => {
        console.log(err);
        self.alertType = "error";
        self.messaggioAlert = Utils.getErrorMessage(err);
        self.showHide_alertPrenotazione(8000);
      }
    );
  }

  savePrenotazioneDto() {
    var self = this;
    self.prenotazioni.selected.adminModify = false;
    if (this.$store.state.roleSel.CCode == "ADM") {
      self.prenotazioni.selected.adminModify = true;
    }
    PrenotazioniService.updatePrenotazione(
      self.prenotazioni.selected,
      (resp) => {
        console.log("updatePrenotazione");
        console.log(resp);
        if (resp.result == 1) {
          // Buon fine
          // Ricarico la griglia
          self.alertType = "success";
          self.messaggioAlert = resp.userMsg.message;
          self.showHide_alertGrid(5000);
          // Aggiorno la griglia dopo il salvataggio
          self.onCloseDetail();
          self.loadPrenotazioni(-1);
        } else {
          // KO
          self.alertType = "error";
          self.messaggioAlert = resp.userMsg.message;
          self.showHide_alertDetail(8000);
        }

        console.log(resp.userMsg.message);
      },
      (err) => {
        console.log(err);
        self.alertType = "error";
        self.messaggioAlert = Utils.getErrorMessage(err);
        self.showHide_alertDetail(8000);
      }
    );
  }
  
  terminaPrenotazioneDto() {
    var self = this;

    PrenotazioniService.terminaPrenotazione(
      self.prenotazioni.selected,
      (resp) => {
        console.log("terminaPrenotazione");
        console.log(resp);
        if (resp.result == 1) {
          // Buon fine

          // Ricarico la griglia
          self.alertType = "success";
          self.messaggioAlert = resp.userMsg.message;
          self.showHide_alertGrid(5000);
          // Aggiorno la griglia dopo l'operazione effettuata
          self.onCloseDetail();
          for (let i = 0; i < self.prenotazioni.source.length; i++) {
            var item = self.prenotazioni.source[i];
            if (item.id == self.prenotazioni.selected.id)
            {
              self.prenotazioni.source.splice(i, 1);
              break;
            }
          }

          this.isHidden = !this.isHidden;
          this.shouldDisable = true;
        } else {
          // KO
          self.alertType = "error";
          self.messaggioAlert = resp.userMsg.message;
          self.showHide_alertDetail(8000);
        }

        console.log(resp.userMsg.message);
      },
      (err) => {
        console.log(err);
        self.alertType = "error";
        self.messaggioAlert = Utils.getErrorMessage(err);
        self.showHide_alertDetail(8000);
      }
    );
  }

  deletePrenotazioneDto(id) {
  var self = this;

    PrenotazioniService.deletePrenotazione(
      id, 
      (resp) => {
        console.log("deletePrenotazione");
        console.log(resp.data);
        if (resp.result == 1) {
          // Buon fine
          self.alertType = "success";
          self.messaggioAlert = resp.userMsg.message;
          self.showHide_alertGrid(5000);
          // Azzero l'oggetto
          self.prenotazioni.selected = null;
          // Aggiorno la griglia dopo l'operazione effettuata
          self.onCloseDetail();
          for (let i = 0; i < self.prenotazioni.source.length; i++) {
            var item = self.prenotazioni.source[i];
            if (item.id == id)
            {
              self.prenotazioni.source.splice(i, 1);
              break;
            }
          }

        } else {
          // KO
          self.alertType = "error";
          self.messaggioAlert = resp.userMsg.message;
          self.showHide_alertDetail(8000);
        }

        self.isHidden = !self.isHidden;
        self.shouldDisable = true;
        console.log(resp.userMsg.message);
      },
      (err) => {
        console.log(err);
        self.alertType = "error";
        self.messaggioAlert = Utils.getErrorMessage(err);
        self.showHide_alertDetail(8000);
      }
    );
  }

}

</script>

